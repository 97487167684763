<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2020/05/29
 * desc: 转基因申报
 */

// import Vue from 'vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import registration from './registration';

export default {
  name: 'transgenic-registration',
  components: {
    nfBreadcrumb,
    registration
  },
  created() {
    this.getList();
  },
  data() {
    return {
      breadList: [{ name: '转基因申报' }],
      dialog_visible: false,
      register_form: { type: '' }, // 登记类型
      tableData: [],
      total_page: 0,
      current_page: 1,
      page_size: 10
    };
  },

  methods: {
    /** 我的列表 **/
    async getList(page) {
      const params = {
        pageNum: page || 1,
        pageSize: this.page_size
      };
      const data = await this.$fetchData.fetchPost(params, '/api/declare/transgene/selectCheckListByPage', 'json');

      if (data.code === '200') {
        this.total_page = data.result.total || 0;
        this.tableData = data.result.rows;
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    pageChange(page) {
      this.getList(page);
    },
    resetForm() {
      this.dialog_visible = false;
      this.$refs.ruleForm.resetFields();
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.dialog_visible = false;
          if (this.register_form.type === 1) {
            this.$router.push({ name: 'registration' });
          } else {
            this.$router.push({ name: 'approveRegistration' });
          }
        } else {
          return false;
        }
      });
    },
    /** 查看驳回信息 */
    viewRejectReason(msg) {
      this.$alert(msg, '驳回信息', {
        confirmButtonText: '确定'
      });
    }
  }
};
</script>

<template>
<div class="module_transgenic_registration">
  <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

  <div class="content">
    <el-button type="primary" class="fr" style="margin: 20px 100px;" @click="dialog_visible = true">新增备案</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="登记类型" align="center" width="400">
        <template slot-scope="scope">
          <span v-if="scope.row.genre === 1" class="register_name"
            @click="$router.push({ name: 'registration', query: { id: scope.row.id } })">
            已取得安全证书转基因农作物南繁试验申请
          </span>
          <span v-else class="register_name" @click="$router.push({ name: 'approveRegistration', query: { id: scope.row.id } })">
            已取得安全证书并通过品种审定的转基因农作物南繁试验申请
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="biologyName" label="生物/作物名称" align="center" />
      <el-table-column prop="createdDate" label="申请时间" align="center" />
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status !== 2">{{ scope.row.status === 0 ? '待审核' : '审核通过' }}</span>
          <el-button v-else type="text" class="reject" @click="viewRejectReason(scope.row.rejectReason)">已驳回</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination :total="total_page" :page-size="page_size" :current-page.sync="current_page"
      @current-change="pageChange" v-if="total_page > 0" background layout="prev, pager, next" />
  </div>

  <el-dialog title="选择登记类型" :visible.sync="dialog_visible" width="31%" center>
    <el-form :model="register_form" ref="ruleForm" class="demo-ruleForm">
      <el-form-item label="" prop="type" :rules="[{ required: true, message: '请选择登记类型', trigger: 'change' }]">
        <el-select v-model="register_form.type" placeholder="请选择登记类型" style="width: 100%;">
          <el-option label="已取得安全证书转基因农作物南繁试验申请" :value="1" />
          <el-option label="已取得安全证书并通过品种审定的转基因农作物南繁试验申请" :value="2" />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
  
</div>
</template>

<style lang="less" scoped>
.module_transgenic_registration {
  .content {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
  }
  .el-pagination {
    text-align: center;
    margin: 40px 0 20px;
  }
  .register_name {
    cursor: pointer;
    &:hover {
      color: #409EFF;
    }
  }
  .reject {
    color: #F56C6C;
  }
}

</style>
